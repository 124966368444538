import * as React from 'react';
import { SOCIAL } from '@core/constants';
import ArrayList from '@components/ArrayList';
import Link from '@components/Link';
import ArtStationIcon from '@assets/socials/artstation.svg';
import FacebookIcon from '@assets/socials/facebook.svg';
import InstagramIcon from '@assets/socials/instagram.svg';
import FabIcon from '@assets/socials/fab.svg';
import XIcon from '@assets/socials/x.svg';
import YouTubeIcon from '@assets/socials/youtube-new.svg';
import { Social, SocialType } from '@interface/gatsby';


type SocialIconProps = {
  type: SocialType;
};

const SocialIcon = ({ type, ...other }: SocialIconProps) => {
  switch (type) {
    case SOCIAL.ARTSTATION:
      return <ArtStationIcon {...other} width={38} height={32}/>;
    case SOCIAL.FACEBOOK:
      return <FacebookIcon {...other} width={32} height={32}/>;
    case SOCIAL.INSTAGRAM:
      return <InstagramIcon {...other} width={32} height={32}/>;
    case SOCIAL.FAB:
      return <FabIcon {...other} width={51} height={32}/>;
    case SOCIAL.X:
      return <XIcon {...other} width={32} height={32}/>;
    case SOCIAL.YOUTUBE:
      return <YouTubeIcon {...other} width={46} height={32}/>;
    default:
      return null;
  }
};

type SocialsProps = {
  items: Social[];
  className?: string;
};

export default function Socials({ items, className }: SocialsProps) {
  return (
    <div className={className}>
      <ArrayList<Social>
        items={items}
        render={({ item }) => (
          <Link
            to={item.url}
            title={item.title}
            external
          >
            <SocialIcon type={item.type}/>
          </Link>
        )}
      />
    </div>
  );
}
